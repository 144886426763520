import React, {useRef, useState} from 'react';
import './HeaderNavigation.scss';
import {HomeIcon, OfficeIcon, ParkingIcon} from '../../icons';
import HeaderNavigationDropdown from './HeaderNavigationDropdown';
import StyledLink from '../StyledLink';
import {HOUSING_ROUTE, OFFICE_ROUTE, PARKING_ROUTE} from '../RouterContants';
import {uuidv4} from '../Tools';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import {useTranslation} from 'react-i18next';

const links = [
  {title: 'Паркинги', url: PARKING_ROUTE, icon: () => <ParkingIcon/>},
  {title: 'Офисы', url: OFFICE_ROUTE, icon: () => <OfficeIcon/>},
];


const HeaderNavigation = ({...props}) => {
  const [isOpen, setOpen] = useState(false);
  const {t} = useTranslation();
  const ref = useRef();

  let showMenuHandler = () => {
    setOpen(true);
  };

  let closeMenuHandler = () => {
    setOpen(false);
  };

  useOnClickOutside(ref, closeMenuHandler);

  return (
      <React.Fragment>
        <div className="dropdown-wrapper"
             onMouseLeave={ closeMenuHandler }
             onMouseEnter={ showMenuHandler }
        >

          <StyledLink title={ t('Жилье') }
                      icon={ () => <HomeIcon/> }
                      url={ HOUSING_ROUTE }
                      large
                      id={ uuidv4() }
                      dropdown
                      hover={isOpen}
                      activeClassName="link--active"/>

          { isOpen &&
            <div className="dropdown"
                 ref={ ref }>
              <HeaderNavigationDropdown closeDropdown={ closeMenuHandler }/>
            </div>
          }
        </div>


        { links.map(link => <StyledLink key={ uuidv4() }
                                        activeClassName="link--active"
                                        icon={ link.icon }
                                        url={ link.url }
                                        title={ t(link.title) }
                                        large/>) }
      </React.Fragment>
  );
};

export default HeaderNavigation;
