export const LOGIN_ROUTE = '/login';
export const REGISTER_ROUTE = '/register';
export const RECOVERY_ROUTE = '/recovery';
export const AGREE_ROUTE = '/agreement-personal-data-processing';
export const PRIVACY_ROUTE = '/privacy-policy';
export const HOW_IT_WORK_ROUTE = '/kak-eto-rabotaet';
export const ABOUT_ROUTE = '/o-nas';
export const SERVICES_ROUTE = '/nashi-uslugi';
export const PARTNERS_ROUTE = '/partneram';


export const HOUSING_ROUTE = '/zhilie';
export const OFFICE_ROUTE = '/ofisy';
export const PARKING_ROUTE = '/parkingi';

export const APARTMENTS_ROUTE = '/zhilie/apartments';
export const LOFT_ROUTE = '/zhilie/loft';
export const FLAT_ROUTE = '/zhilie/flat';

export const DAILY_ROUTE = '/zhilie/posutochno';
export const LONG_TERM_ROUTE = '/zhilie/dolgosrochno';
export const EVENTS_ROUTE = '/zhilie/apartamenty-loft-na-tulskoy-3';


export const PROFILE_COMMUNAL_ROUTE = '/profile/communal';
export const PROFILE_PAYMENTS_ROUTE = '/profile/payments';
export const PROFILE_BOOKINGS_ROUTE = '/profile/bookings';
export const PROFILE_SUPPORT_ROUTE = '/profile/support';
export const PROFILE_SETTINGS_ROUTE = '/profile/settings';


export const EMAIL_VERIFY_ROUTE = '/email/verify';
export const PASSWORD_RESET_ROUTE = '/password/reset';

