import React from 'react';
import './LogoutButton.scss';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {logout} from '../../actions/authActions';
import {useTranslation} from 'react-i18next';

const LogoutButton = ({...props}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    dispatch(logout());
    history.push({
      pathname: '/'
    });
  };

  return (
      <div className="logout-button"
           onClick={ handleLogout }>
        { t('Выход') }
      </div>
  );
};

export default LogoutButton;
