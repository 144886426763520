import React from 'react';
import './Button.scss';
import {useTranslation} from 'react-i18next';


const Button = ({
                  className = '',
                  activeClassName = 'button--active',
                  submit = false,
                  title = '',
                  filter = false,
                  xs = false,
                  small = false,
                  large = false,
                  medium = false,
                  modal = false,
                  icon: Icon = null,
                  style = {},
                  fullWidth = false,
                  children,
                  dropdown = false,
                  disabled = false,
                  active, ...props
                }) => {
  const {t} = useTranslation();
  return (
      <React.Fragment>
        <button className={ `button 
                ${ xs ? 'button--xs' : '' }
                ${ small ? 'button--small' : '' }
                ${ medium ? 'button--medium' : '' }
                ${ large ? 'button--large' : '' }
                ${ dropdown ? 'button--has-dropdown' : '' }
                ${ active ? `${ activeClassName }` : '' }
                ${ Icon ? 'button--has-icon' : '' }
                ${ submit ? 'button--submit' : '' }
                ${ modal ? 'button--modal' : '' }
                ${ fullWidth ? 'button--full-width' : '' }
                ${ filter ? 'button--filter' : '' }
                ${ disabled ? 'button--disabled' : '' }
               ${ className }
                ` }
                style={ {...style} }
                { ...props }>
          { Icon && (
              <div className="button__icon">
                <Icon/>
              </div>
          ) }

          {title &&
            <div className="button__text">
              { t(title) }
            </div>
          }


          { children }
        </button>
      </React.Fragment>
  );
};

export default Button;
