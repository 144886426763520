import React, {PureComponent} from 'react';
import Modal from '../Modal';
import './TransferModal.scss';
import {connect} from 'react-redux';
import {closeTransferModal} from '../../actions/modalActions';
import TransferForm from '../TransferForm/TransferForm';


class TransferModal extends PureComponent {

  handleClose = () => {
    this.props.closeTransferModal()
  };


  render() {
    const { isOpen } = this.props;

    return (
        <React.Fragment>
          <Modal isOpen={isOpen}
                 onRequestClose={this.handleClose}
                 title="Передать показания">
            <TransferForm/>
          </Modal>
        </React.Fragment>
    )
  };
  }



let mapStateToProps = (state) => ({
  isOpen: state.modal.transfer.isOpen,
});

export default connect(mapStateToProps,{closeTransferModal})(TransferModal);
