import React from 'react';
import './Description.scss';
import {useTranslation} from 'react-i18next';

const Description = ({className = '', description = '', center = false, action = '', ...props}) => {
  const {t} = useTranslation();
  return (
      <div className={ `description 
                      ${ center ? 'description--center' : '' }
                      ${ className }` }>
        { t(description) }
        { action &&
        <>
          <br/>
          { action }
        </>
        }
      </div>
  );
};

export default Description;
