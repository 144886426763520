import React, {PureComponent} from 'react';
import './ResetPasswordModal.scss';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Modal from '../Modal';
import {notification} from '../Tools';
import {fetchUser} from '../../actions/authActions';
import ResetPasswordForm from '../ResetPasswordForm/ResetPasswordForm';
import {SUCCESS} from '../Constants';

const initialState = {
  isOpen: false
};


class ResetPasswordModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount() {
    this.setState({
      isOpen: this.props.isOpen || false,
    })
  }

  handleClose = () => {
    this.setState({
      isOpen: false,
    });

    this.props.history.push({
      pathname: `/`
    });
  };

  handleSubmit = () => {
    this.setState(initialState);
    this.handleClose();
    notification(SUCCESS, 'Вы успешно обновили пароль');
    this.props.fetchUser();
  };

  render() {
    const {isOpen} = this.props;

    return (
        <Modal isOpen={isOpen}
               onRequestClose={this.handleClose}
               title={'Смена пароля'}>

          <ResetPasswordForm
              onSubmit={this.handleSubmit}/>
        </Modal>
    )
  }
}

export default connect(null, {fetchUser})(withRouter(ResetPasswordModal))
