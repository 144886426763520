import React, {useEffect, useState} from 'react';
import './LanguageSwitch.scss';
import {useTranslation} from 'react-i18next';

const LanguageSwitch = () => {
  const {i18n} = useTranslation();
  const [activeLang, setActiveLang] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    setActiveLang(i18n.language);
  }, [setActiveLang, i18n.language]);

  return (
      <div className="language-switch">
        { process.env.REACT_APP_LOCALES.split(',').map((locale) => (
            <button className={ `language-switch__button 
                          ${ activeLang === locale ? 'language-switch__button--active' : '' }` }
                    key={ locale }
                    onClick={ () => changeLanguage(locale) }>
              { locale }
            </button>
        )) }
      </div>
  );
};

export default LanguageSwitch;
