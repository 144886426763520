import React from 'react';
import {Link} from 'react-router-dom';
import {
  PROFILE_BOOKINGS_ROUTE,
  PROFILE_COMMUNAL_ROUTE,
  PROFILE_PAYMENTS_ROUTE, PROFILE_SETTINGS_ROUTE,
  PROFILE_SUPPORT_ROUTE
} from '../RouterContants';
import {useTranslation} from 'react-i18next';



const HeaderProfileLinks = ({className = '', medium = false, ...props}) => {
  const {t} = useTranslation();

  const links = [
    {title: t('Заказы'), url: PROFILE_BOOKINGS_ROUTE},
    {title: t('Оплаты'), url: PROFILE_PAYMENTS_ROUTE},
    {title: t('Коммуналка'), url: PROFILE_COMMUNAL_ROUTE},
    {title: t('Поддержка'), url: PROFILE_SUPPORT_ROUTE},
    {title: t('Настройки'), url: PROFILE_SETTINGS_ROUTE},
  ];


  return (
      <React.Fragment>
        <div className={className}>
          {links.map((link, index) => (
              <Link to={link.url}
                 className="link"
                 key={index}>{link.title}</Link>
          ))}
        </div>
      </React.Fragment>
  );
};

export default HeaderProfileLinks;
