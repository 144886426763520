import React from 'react';
import HeaderLinks from '../HeaderLinks/HeaderLinks';
import ProfileButtons from '../ProfileButtons';
import {useSelector} from 'react-redux';
import LoginButtons from '../LoginButtons';
import ButtonWithDropdown from '../ButtonWithDropdown';
import {MenuIcon} from '../../icons';
import Wrapper from '../Wrapper';
import LanguageSwitch from '../LanguageSwitch';
import {useTranslation} from 'react-i18next';


const  HeaderTop = ({...props}) => {
  const auth = useSelector(state => state.auth);
  const {i18n} = useTranslation();

  return (
      <div className="header__top">
        <ButtonWithDropdown
                            // buttonTitle="Меню"
                            medium
                            dropdownIcon={() => <MenuIcon/>}
                            dropdownComponent={() => (
                                <Wrapper className="dropdown-links">
                                  <HeaderLinks medium />
                                </Wrapper>
                            )}
                            classNameDropdown="header__navigation-dropdown"
                            classNameButton="header__mobile-dropdown-button button--mobile"/>

        <Wrapper className="header__top-navigation">
          <HeaderLinks medium/>
        </Wrapper>


        <div className="header__top-inner">

          {i18n.languages.length > 1 && <LanguageSwitch/>}

          {auth.isAuthenticated
              ? <ProfileButtons/>
              : <LoginButtons/>
          }

        </div>
      </div>
  );
}

export default HeaderTop;
