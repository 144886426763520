import React from 'react';
import './ModifiedLink.scss';
import {Link} from 'react-router-dom';

const ModifiedLink = ({className = '', button = false, title, url, ...props}) => {
  return (
      <Link className={`modify-link 
                        ${button ? 'modify-link--button': ''}
                        ${className}`}
            to={url}
            {...props}>
        {title}
      </Link>
  )
};

export default ModifiedLink;
