import ModifiedNavLink from '../ModifiedNavLink';
import {UserIcon} from '../../icons';
import React from 'react';
import {REGISTER_ROUTE} from '../RouterContants';
import {useTranslation} from 'react-i18next';


const LoginButtons = ({props}) => {
  const {t} = useTranslation();

  return (
      <React.Fragment>
        <div className="profile-links">
          <ModifiedNavLink url={'/login'}
                           icon={() => <UserIcon/>}
                           title={t('Вход')}>
          </ModifiedNavLink>
          <div className="profile-links__divider link__divider">/</div>
          <ModifiedNavLink url={REGISTER_ROUTE}
                           title={t('Регистрация')}>
          </ModifiedNavLink>
        </div>
      </React.Fragment>
  )
};

export default LoginButtons;
