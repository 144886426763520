import React from 'react';

const Wrapper = ({className = '', children,  ...props}) => {
  return (
      <div className={className}>
        {children}
      </div>
  );
};

export default Wrapper;
