import React, {PureComponent} from 'react';
import {Provider} from 'react-redux';
import {fetchUser} from '../actions/authActions';
import {handleLocalStorage, handleRemoveLocalStorage} from './Tools';
import {SITE_AUTH_CHECK} from './Constants';
import Errors from './Errors';
import {add, getUnixTime} from 'date-fns';

class StoreProvider extends PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      errors: new Errors()
    };
  }

  startSessionTimer () {
    const date = add(new Date(), {hours: 2});
    const minutes = (getUnixTime(date) - getUnixTime(new Date())) * 1000;

    setTimeout(() => {
      handleRemoveLocalStorage(SITE_AUTH_CHECK);
    }, minutes);

  }

  componentDidMount () {
    if ( handleLocalStorage(SITE_AUTH_CHECK) ) {

      this.startSessionTimer();

      this.props.store.dispatch(fetchUser())
          .then(() => {})
          .catch(errors => {
            this.setState({
              errors: new Errors(errors)
            });
          });
    }
  }


  render () {
    return (
        <Provider store={ this.props.store }>
          { this.props.children }
        </Provider>
    );
  }
}

export default StoreProvider;
