export default class Errors {
  constructor(errors = {}) {
    this.errors = errors;
  }

  hasError = (key) => {
    return this.errors.hasOwnProperty(key);
  };

  getError = (key) => {
    return this.errors[key] || null;
  };

  removeError = (key) => {
    delete this.errors[key]

    return this.errors;
  }
}
