import React from 'react';
import ReactDOM from 'react-dom';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import './index.scss';
import App from './components/App';
import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import appReducer from './reducers';
import axios from 'axios';
import StoreProvider from './components/StoreProvider';
import {DEVELOPMENT} from './components/Constants';
import './i18n';
import './components/DatePickerSettings';
import {BrowserRouter} from 'react-router-dom';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && process.env.NODE_ENV === DEVELOPMENT
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;

const initialState = {};

const store = createStore(
    appReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
    <StoreProvider store={ store }>
      <BrowserRouter>
        <GoogleReCaptchaProvider reCaptchaKey={ process.env.REACT_APP_RECAPTCHA }
                                 language="ru"
                                 useEnterprise={ true }
                                 scriptProps={ {
                                   async: true,
                                   defer: true,
                                   appendTo: 'head',
                                   nonce: undefined
                                 } }>
          <App/>
        </GoogleReCaptchaProvider>
      </BrowserRouter>
    </StoreProvider>
    ,
    document.getElementById('root')
);
