import React, {useRef, useState} from 'react';
import Button from '../Button';
import useOnClickOutside from '../../hooks/useOnClickOutside';

const ButtonWithDropdown = ({
                              buttonTitle = '', classNameButton = '',
                              buttonSize,
                              dropdownIcon: Icon = null,
                              classNameDropdown = '', dropdownComponent: Dropdown = null, ...props
                            }) => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef();

  let showMenuHandler = () => {
    setOpen(!isOpen);
  };

  useOnClickOutside(ref, showMenuHandler);

  return (
      <Button dropdown
              className={ classNameButton }
              icon={ () => <Icon/> }
              { ...props }
              active={ isOpen }
              onClick={ showMenuHandler }
              title={ buttonTitle }>
        { isOpen &&
        <div className={ classNameDropdown }
             ref={ ref }>
          <Dropdown onClick={ showMenuHandler }/>
        </div>
        }
      </Button>
  );
};

export default ButtonWithDropdown;
