import React from 'react';
import { ReactComponent as Rent } from './main-page/favorable-rent.svg';
import { ReactComponent as RentMobile } from './main-page/favorable-rent-mobile.svg';

import { ReactComponent as Logo } from './logo-main.svg';
import { ReactComponent as LogoW } from './logo-white.svg';
import { ReactComponent as LogoM } from './mini-logo.svg';

import { ReactComponent as NoCommission } from './advantages/no-commission.svg';
import { ReactComponent as Rentabelno } from './advantages/rentabelno.svg';
import { ReactComponent as Shield } from './advantages/shield.svg';

import { ReactComponent as Metro } from './information/metro.svg';
import { ReactComponent as Offer } from './information/offer.svg';
import { ReactComponent as Registration } from './information/registration.svg';

import { ReactComponent as FirstStep } from './rent-points/step-first.svg';
import { ReactComponent as SecondStep } from './rent-points/step-second.svg';
import { ReactComponent as ThirdStep } from './rent-points/step-third.svg';


import { ReactComponent as AddObject } from './partners/add-object.svg';

export const FavorableRent = (props) => {
  return <Rent {...props}/>
}

export const FavorableRentMobile = (props) => {
  return <RentMobile {...props}/>
}

export const LogoMain = (props) => {
  return <Logo {...props}/>
}

export const LogoWhite = (props) => {
  return <LogoW {...props}/>
}

export const LogoMini = (props) => {
  return <LogoM {...props}/>
}

export const NoCommissionIcon = (props) => {
  return <NoCommission {...props}/>
}

export const RentabelnoIcon = (props) => {
  return <Rentabelno {...props}/>
}

export const ShieldIcon = (props) => {
  return <Shield {...props}/>
}

export const MetroIcon = (props) => {
  return <Metro {...props}/>
}

export const OfferIcon = (props) => {
  return <Offer {...props}/>
}

export const RegistrationIcon = (props) => {
  return <Registration {...props}/>
}

export const FirstStepRentIcon = (props) => {
  return <FirstStep {...props}/>
}

export const SecondStepRentIcon = (props) => {
  return <SecondStep {...props}/>
}

export const ThirdStepRentIcon = (props) => {
  return <ThirdStep {...props}/>
}

export const AddObjectIcon = (props) => {
  return <AddObject {...props}/>
}
