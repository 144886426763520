import React from 'react';
import './NotificationCount.scss';

const NotificationCount = ({className = '', count, ...props}) => {
  return (
      <div className={ `notification ${ className }` }>
        <span>
          { count }
        </span>
      </div>
  );
};

export default NotificationCount;
