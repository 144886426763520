import * as actionTypes from './../actions/actionTypes';
import Errors from '../components/Errors';

const initialState = {
  user: null,
  isFetching: false,
  isAuthenticated: false,
  errors: new Errors({})
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_USER:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.LOGIN:
    case actionTypes.FETCH_USER:
    case actionTypes.REGISTER:
      return {
        ...state,
        user: action.user,
        isFetching: false,
        isAuthenticated: true,
      };
    case actionTypes.AUTH_ERROR:
    case actionTypes.REGISTER_ERROR:
      return {
        ...state,
        user: null,
        isFetching: false,
        isAuthenticated: false,
        errors: new Errors(action.errors)
      };
    case actionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
