import React, {memo, useState} from 'react';
import NoImage from '../../images/nophoto.jpg';
import {Transition} from 'react-transition-group';
import LazyLoad from 'react-lazyload';

const ObjectPhoto = ({className = '', image, name, ...props}) => {
  const [load, setLoad] = useState(false);
  const [loadNoImage, setLoadNoImg] = useState(false);


  const duration = 300;
  const height = 200;

  const defaultStyle = {
    transition: `opacity ${ duration }ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: {opacity: 1},
    entered: {opacity: 1},
    exiting: {opacity: 0},
    exited: {opacity: 0},
  };

  if ( !image ) {
    return (
        <LazyLoad height={ height }
                  once
                  className={className}>
          <Transition in={ loadNoImage }
                      timeout={ duration }>
            { state => <img src={ NoImage }
                            style={ {
                              ...defaultStyle,
                              ...transitionStyles[ state ]
                            } }
                            onLoad={ () => setLoadNoImg(true) }
                            className={ `object__no-image` }
                            alt={ name }/>
            }

          </Transition>
        </LazyLoad>

    );
  }

  return (
      <LazyLoad height={ height }
                once
                className={`${className} 
                            ${!load ? `${className}--loading` : ''}`}>
        <Transition in={ load }
                    timeout={ duration }>
          { state => <img src={ image }
                          style={ {
                            ...defaultStyle,
                            ...transitionStyles[ state ]
                          } }
                          onLoad={ () => setLoad(true) }
                          className={ `object__image` }
                          alt={ name }/>
          }

        </Transition>
      </LazyLoad>
  );
};

export default memo(ObjectPhoto);
