import React from 'react';
import './Contacts.scss';
import { useTranslation } from 'react-i18next';
import { useCurrentLocaleVersion } from '../../hooks/useCurrentLocaleVersion';

const Contacts = ({ className = '', ...props }) => {
  const { t } = useTranslation();
  const { isRuVersion } = useCurrentLocaleVersion();

  return (
    <div className={ `contacts ${ className }` }>
      { isRuVersion() && (
        <div className="contacts__city">
          { t('Московская обл., г. Балашиха, п-кт Ленина 32А') }
        </div>
      ) }

      <div className="contacts__phone">
        <a href="mailto:docs@rentabelno.com">
          docs@rentabelno.com
        </a>
      </div>
    </div>
  );
};

export default Contacts;
