import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';

const GuestRoute = ({...props}) => {
  const auth = useSelector(state => state.auth);

  if (auth.isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
      <Route {...props} />
  );
};

export default GuestRoute;
