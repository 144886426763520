import React from 'react';
import './Header.scss';
import Container from '../Container';
import HeaderTop from './HeaderTop';
import HeaderBottom from './HeaderBottom';

const Header = (props) => {
  return (
    <div className="header">
      <Container className="header__container">
        <HeaderTop/>
        <HeaderBottom/>
      </Container>
    </div>
  )
};

export default Header;
