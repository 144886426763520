import React from 'react';
import './Footer.scss';
import Container from '../Container';
import FooterLogo from '../FooterLogo';
import Copyright from '../Copyright';
import Contacts from '../Contacts';
import Menu from '../Menu';
import Social from '../Social';
import {
  ABOUT_ROUTE,
  HOUSING_ROUTE,
  HOW_IT_WORK_ROUTE,
  OFFICE_ROUTE,
  PARKING_ROUTE,
  PARTNERS_ROUTE,
  SERVICES_ROUTE
} from '../RouterContants';
import {useTranslation} from 'react-i18next';

const informationLinks = [
  {title: 'Партнерам', url: PARTNERS_ROUTE},
  {title: 'Наши услуги', url: SERVICES_ROUTE},
  {title: 'Как это работает?', url: HOW_IT_WORK_ROUTE},
  {title: 'О нас', url: ABOUT_ROUTE},
];

const categories = [
  {title: 'Жилье', url: HOUSING_ROUTE},
  {title: 'Паркинги', url: PARKING_ROUTE},
  {title: 'Офисы', url: OFFICE_ROUTE},
];


const Footer = ({...props}) => {
  const {t} = useTranslation();

  return (
      <div className="footer">
        <Container className="footer__container">
          <div className="footer__inner">
            <div className="footer__info">
              <FooterLogo className="footer__logo"/>
              <Copyright className="footer__copyright"/>
              <Contacts className="footer__contacts"/>
            </div>
            <Menu className="footer__menu"
                  label={ t('Информация') }
                  links={ informationLinks }/>
            <Menu className="footer__menu"
                  label={ t('Категории') }
                  links={ categories }/>
            <Social className="footer__social"
                    label={ t('Мы в социалках') }/>
          </div>
          <div className="footer__documents">
            {/*<Link to={ PRIVACY_ROUTE }*/}
            {/*      className="footer__privacy">*/}
            {/*  {t("Политика конфиденциальности")}*/}
            {/*</Link>*/}
            {/*<StyledLink to={"/agreement-personal-data-processing"}*/ }
            {/*      className="footer__agreement">*/ }
            {/*  Согласие на обработку персональных данных*/ }
            {/*</StyledLink>*/ }
          </div>
        </Container>
      </div>
  );
};

export default Footer;
