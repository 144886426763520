import React from 'react';
import { ReactComponent as Check } from './check.svg';
import { ReactComponent as Cold } from './cold.svg';
import { ReactComponent as Lightning } from './lightning.svg';
import { ReactComponent as Error } from './error.svg';
import { ReactComponent as Gas } from './fire.svg';
import { ReactComponent as Gear } from './gear.svg';
import { ReactComponent as Help } from './help.svg';
import { ReactComponent as Home } from './home.svg';
import { ReactComponent as Hot } from './hot.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as Mail } from './mail.svg';
import { ReactComponent as Office } from './office.svg';
import { ReactComponent as Parking } from './parking.svg';
import { ReactComponent as Password } from './password.svg';
import { ReactComponent as Tv } from './tv.svg';
import { ReactComponent as User } from './user.svg';
import { ReactComponent as Warning } from './warning.svg';
import { ReactComponent as Wifi } from './wifi.svg';
import { ReactComponent as Buy } from './buy.svg';
import { ReactComponent as Support } from './question.svg';

import { ReactComponent as Location } from '../icons/contacts/location.svg';
import { ReactComponent as HotLine } from '../icons/contacts/hot-line.svg';

import { ReactComponent as FeatureCoin } from '../icons/feature/feature-coin.svg';
import { ReactComponent as FeatureComfort } from '../icons/feature/feature-comfort.svg';
import { ReactComponent as FeatureLocation } from '../icons/feature/feature-location.svg';
import { ReactComponent as FeatureParking } from '../icons/feature/feature-parking.svg';
import { ReactComponent as FeatureSmile } from '../icons/feature/feature-smile.svg';
import { ReactComponent as FeatureStatistic } from '../icons/feature/feature-statistic.svg';
import { ReactComponent as FeatureTime } from '../icons/feature/feature-time.svg';


export const FeatureCoinIcon = (props) => {
  return <FeatureCoin {...props}/>
}

export const FeatureComfortIcon = (props) => {
  return <FeatureComfort {...props}/>
}

export const FeatureLocationIcon = (props) => {
  return <FeatureLocation {...props}/>
}

export const FeatureParkingIcon = (props) => {
  return <FeatureParking {...props}/>
}

export const FeatureSmileIcon = (props) => {
  return <FeatureSmile {...props}/>
}

export const FeatureStatisticIcon = (props) => {
  return <FeatureStatistic {...props}/>
}

export const FeatureTimeIcon = (props) => {
  return <FeatureTime {...props}/>
}

export const LocationIcon = (props) => {
  return <Location {...props}/>
}

export const HotLinePhoneIcon = (props) => {
  return <HotLine {...props}/>
}
export const CheckIcon = (props) => {
  return <Check {...props} />;
};

export const ColdIcon = (props) => {
  return <Cold {...props} />;
};

export const LightningIcon = (props) => {
  return <Lightning {...props} />;
};

export const ErrorIcon = (props) => {
  return <Error {...props} />;
};

export const GasIcon = (props) => {
  return <Gas {...props} />;
};

export const GearIcon = (props) => {
  return <Gear {...props} />;
};

export const HelpIcon = (props) => {
  return <Help {...props} />;
};

export const HomeIcon = (props) => {
  return <Home {...props} />;
};

export const HotIcon = (props) => {
  return <Hot {...props} />;
};

export const InfoIcon = (props) => {
  return <Info {...props} />;
};

export const MailIcon = (props) => {
  return <Mail {...props} />;
};

export const OfficeIcon = (props) => {
  return <Office {...props} />;
};

export const ParkingIcon = (props) => {
  return <Parking {...props} />;
};

export const PasswordIcon = (props) => {
  return <Password {...props} />;
};

export const TvIcon = (props) => {
  return <Tv {...props} />;
};

export const UserIcon = (props) => {
  return <User {...props} />;
};

export const WarningIcon = (props) => {
  return <Warning {...props} />;
};

export const WifiIcon = (props) => {
  return <Wifi {...props} />;
};

export const BuyIcon = (props) => {
  return <Buy {...props} />;
};

export const SupportIcon = (props) => {
  return <Support {...props} />;
};


export const NotificationIcon = ({className = '', ...props}) => {
  return <i className={`icon-notification ${className}`} {...props} />;
};

export const TimeIcon = ({className = '', ...props}) => {
  return <i className={`icon-time ${className}`} {...props} />;
};
export const SmallArrowDownIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-down-arrow ${className}`} />;
};

export const DownloadIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-download ${className}`} />;
};

export const ExclamationIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-exclamation ${className}`} />;
};

export const ExitIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-exit ${className}`} />;
};

export const FileIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-file ${className}`} />;
};

export const FilterIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-filter ${className}`} />;
};

export const FilterCloseIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-filter-close ${className}`} />;
};

export const EyeHideIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-hide ${className}`} />;
};

export const ArrowLeftIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-left ${className}`} />;
};

export const SmallArrowLeftIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-left-arrow ${className}`} />;
};

export const MenuIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-menu ${className}`} />;
};

export const MinusIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-minus ${className}`} />;
};

export const PlusIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-plus ${className}`} />;
};

export const QuestionIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-question ${className}`} />;
};

export const ArrowRightIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-right ${className}`} />;
};

export const SmallArrowRightIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-right-arrow ${className}`} />;
};

export const SearchIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-search ${className}`} />;
};

export const settingsIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-settings ${className}`} />;
};

export const EyeShowIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-show ${className}`} />;
};

export const ArrowUpIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-up ${className}`} />;
};

export const SmallArrowUpIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-up-arrow ${className}`} />;
};

export const UploadIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-upload ${className}`} />;
};

export const AttachIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-attach ${className}`} />;
};

export const BellIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-bell ${className}`} />;
};

export const BulbIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-bulb ${className}`} />;
};

export const CalendarIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-calendar ${className}`} />;
};

export const ApproveIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-check ${className}`} />;
};

export const ClockIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-clock ${className}`} />;
};

export const CloseIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-close ${className}`} />;
};

export const DeleteIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-delete ${className}`} />;
};

export const ArrowDownIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-down-arrow-2 ${className}`} />;
};

export const PhoneIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-phone ${className}`} />;
};

export const VkIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-vk ${className}`} />;
};

export const InstaIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-insta ${className}`} />;
};

export const FbIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-fb ${className}`} />;
};

export const StarIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-star ${className}`} />;
};

export const MoonIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-moon ${className}`} />;
};

export const ChevronsLeftIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-chevrons-left ${className}`} />;
};

export const ChevronsRightIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-chevrons-right ${className}`} />;
};

export const CalendarSecondIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-calendar-second ${className}`} />;
};

export const MeterIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-meter ${className}`} />;
};


export const ApartmentIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-apartment ${className}`} />;
};

export const ApartmentRoomIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-apartment-room ${className}`} />;
};

export const ApartmentLoftIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-apartment-loft ${className}`} />;
};

export const StatusLockIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-lock ${className}`} />;
};

export const StatusLockOpenIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-lock-open ${className}`} />;
};

export const MetroIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-metro ${className}`} />;
};

export const FlagIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-flag ${className}`} />;
};

export const PinIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-pin ${className}`} />;
};
