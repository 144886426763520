import React, { Fragment, lazy, memo, Suspense, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import Content from './Content';
import Header from './Header';
import Footer from './Footer';
import { useSelector } from 'react-redux';

import Notifications from './Notifications';
import ScrollToTop from './Router/ScrollToTop';
import EmailVerifyModal from './EmailVerifyModal/EmailVerifyModal';
import ResetPasswordModal from './ResetPasswordModal/ResetPasswordModal';
import TransferModal from './TransferModal/TransferModal';
import PaymentModal from './PaymentModal/PaymentModal';
import {
  ABOUT_ROUTE,
  AGREE_ROUTE,
  APARTMENTS_ROUTE,
  DAILY_ROUTE,
  EMAIL_VERIFY_ROUTE,
  FLAT_ROUTE,
  HOUSING_ROUTE,
  HOW_IT_WORK_ROUTE,
  LOFT_ROUTE,
  LOGIN_ROUTE,
  LONG_TERM_ROUTE,
  OFFICE_ROUTE,
  PARKING_ROUTE,
  PARTNERS_ROUTE,
  PASSWORD_RESET_ROUTE,
  PRIVACY_ROUTE,
  PROFILE_BOOKINGS_ROUTE,
  PROFILE_COMMUNAL_ROUTE,
  PROFILE_PAYMENTS_ROUTE,
  PROFILE_SETTINGS_ROUTE,
  PROFILE_SUPPORT_ROUTE,
  RECOVERY_ROUTE,
  REGISTER_ROUTE,
  SERVICES_ROUTE
} from './RouterContants';
import ScrollToTopButton from './ScrollToTopButton';
import SuccessModal from './SuccessModal';
import ErrorBoundary from './ErrorBoundary';
import YandexMetrika from './YandexMetrika';
import { EMAIL_REDIRECT_SUPPORT, SITE_AUTH_CHECK, SUPPORT_REDIRECT_VARIABLE } from './Constants';


import queryString from 'query-string';
import { handleLocalStorage, handleRemoveLocalStorage } from './Tools';
import GuestRoute from './Router/GuestRoute';
import SuspenseLoader from './SuspenseLoader';
import Page404 from '../pages/ErrorPage/Page404';
import Meta from './Meta';

const LoginPage = lazy(() => import('../pages/Login/LoginPage'));
const RegisterPage = lazy(() => import('../pages/Register/RegisterPage'));
const RecoveryPasswordPage = lazy(() => import('../pages/RecoveryPassword/RecoveryPasswordPage'));
const SupportPage = lazy(() => import('../pages/Support/SupportPage'));
const SettingsPage = lazy(() => import('../pages/Settings/SettingsPage'));
const PaymentsPage = lazy(() => import('../pages/Payments/PaymentsPage'));
const BookingsPage = lazy(() => import('../pages/Bookings/BookingsPage'));
const CommunalPage = lazy(() => import('../pages/Communal/CommunalPage'));
const MainPage = lazy(() => import('../pages/MainPage/MainPage'));
const PartnersPage = lazy(() => import('../pages/Partners/PartnersPage'));
const ServicesPage = lazy(() => import('../pages/Services/ServicesPage'));
const HowItWorkPage = lazy(() => import('../pages/HowItWork/HowItWorkPage'));
const AboutPage = lazy(() => import('../pages/About/AboutPage'));

const HousingListPage = lazy(() => import('../pages/Housing/HousingListPage'));
const OfficeListPage = lazy(() => import('../pages/Office/OfficeListPage'));
const ParkingListPage = lazy(() => import('../pages/Parking/ParkingListPage'));
const HousingApartmentsListPage = lazy(() => import('../pages/Housing/HousingApartmentsListPage'));
const HousingLoftsListPage = lazy(() => import('../pages/Housing/HousingLoftsListPage'));
const HousingLongTermListPage = lazy(() => import('../pages/Housing/HousingLongTermListPage'));
const PrivacyPage = lazy(() => import('../pages/Privacy/PrivacyPage'));
const HousePage = lazy(() => import('../pages/Housing/HousePage'));
const AgreementPersonalDataPage = lazy(() => import('../pages/AgreementPersonalData/AgreementPersonalDataPage'));
const HousingFlatsListPage = lazy(() => import('../pages/Housing/HousingFlatsListPage'));
const HousingDailyTermListPage = lazy(() => import('../pages/Housing/HousingDailyTermListPage'));

const App = ({ ...props }) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const [userAuth, setUserAuth] = useState(false);
  const location = useLocation();
  const history = useHistory();
  let userAuthFromLS = localStorage.getItem(SITE_AUTH_CHECK);

  useEffect(() => {
    const query = queryString.parse(location.search);
    let queryToObj = Object.assign({}, query);
    if ( queryToObj.hasOwnProperty(EMAIL_REDIRECT_SUPPORT) ) {
      handleLocalStorage(SUPPORT_REDIRECT_VARIABLE, JSON.stringify(queryToObj));

      if ( isAuthenticated ) {
        setTimeout(() => {
          history.push(PROFILE_SUPPORT_ROUTE);
          handleRemoveLocalStorage(SUPPORT_REDIRECT_VARIABLE);
        }, 100);
      }

      if ( !isAuthenticated ) {
        setTimeout(() => {
          history.push(LOGIN_ROUTE);
        }, 100);
      }

    }
  }, [location.search, history, isAuthenticated]);

  useEffect(() => {
    //we che auth from LS
    //from dont see page 404 from new tab browser
    if ( !!userAuthFromLS ) {
      setUserAuth(!!userAuthFromLS);
    } else {
      setUserAuth(isAuthenticated);
    }
  }, [isAuthenticated, userAuthFromLS]);


  return (
    <Fragment>
      <Meta/>
      <YandexMetrika/>
      <ScrollToTop/>

      <div className="wrapper">
        <Header/>

        <Suspense fallback={ <SuspenseLoader/> }>
          <Content>
            <ErrorBoundary>
              <Switch>

                <Route exact path={ '/' } component={ MainPage }/>

                <GuestRoute exact path={ LOGIN_ROUTE } component={ LoginPage }/>
                <GuestRoute exact path={ REGISTER_ROUTE } component={ RegisterPage }/>
                <GuestRoute exact path={ RECOVERY_ROUTE } component={ RecoveryPasswordPage }/>

                <Route exact path={ HOUSING_ROUTE } component={ HousingListPage }/>

                <Route exact path={ APARTMENTS_ROUTE } component={ HousingApartmentsListPage }/>
                <Route exact path={ LOFT_ROUTE } component={ HousingLoftsListPage }/>
                <Route exact path={ FLAT_ROUTE } component={ HousingFlatsListPage }/>

                <Route exact path={ DAILY_ROUTE } component={ HousingDailyTermListPage }/>
                <Route exact path={ LONG_TERM_ROUTE } component={ HousingLongTermListPage }/>

                <Route path={ `${ HOUSING_ROUTE }/:slug` }
                       render={ props => <HousePage key={ props.match.params.id } { ...props }/> }/>

                <Route exact path={ `${ OFFICE_ROUTE }` } component={ OfficeListPage }/>
                <Route path={ `${ OFFICE_ROUTE }/:slug` }
                       render={ props => <HousePage key={ props.match.params.id } { ...props }/> }/>

                <Route exact path={ PARKING_ROUTE } component={ ParkingListPage }/>
                <Route path={ `${ PARKING_ROUTE }/:slug` }
                       render={ props => <HousePage key={ props.match.params.id } { ...props }/> }/>

                <Route exact path={ PARTNERS_ROUTE } component={ PartnersPage }/>
                <Route exact path={ SERVICES_ROUTE } component={ ServicesPage }/>
                <Route exact path={ HOW_IT_WORK_ROUTE } component={ HowItWorkPage }/>
                <Route exact path={ ABOUT_ROUTE } component={ AboutPage }/>


                <Route exact path={ PRIVACY_ROUTE } component={ PrivacyPage }/>
                <Route exact path={ AGREE_ROUTE } component={ AgreementPersonalDataPage }/>


                <Route exact path={ `${ EMAIL_VERIFY_ROUTE }/:client_id` }>
                  <EmailVerifyModal isOpen={ true }/>
                  <Route path={ `/` } component={ MainPage }/>
                </Route>

                <Route exact path={ PASSWORD_RESET_ROUTE }>
                  <ResetPasswordModal isOpen={ true }/>
                  <Route path={ `/` } component={ MainPage }/>
                </Route>


                { ( isAuthenticated && userAuth ) &&
                  <Route exact path={ [PROFILE_BOOKINGS_ROUTE,
                    PROFILE_PAYMENTS_ROUTE,
                    PROFILE_COMMUNAL_ROUTE,
                    PROFILE_SUPPORT_ROUTE,
                    PROFILE_SETTINGS_ROUTE
                  ] }>
                    <Route exact path={ PROFILE_BOOKINGS_ROUTE } component={ BookingsPage }/>
                    <Route exact path={ PROFILE_PAYMENTS_ROUTE } component={ PaymentsPage }/>
                    <Route exact path={ PROFILE_COMMUNAL_ROUTE } component={ CommunalPage }/>
                    <Route exact path={ PROFILE_SUPPORT_ROUTE } component={ SupportPage }/>
                    <Route exact path={ PROFILE_SETTINGS_ROUTE } component={ SettingsPage }/>
                  </Route>
                }


                <Route path="*" component={ Page404 }/>
              </Switch>
            </ErrorBoundary>
          </Content>

        </Suspense>


        <Footer/>
      </div>

      <TransferModal/>
      <PaymentModal/>
      <SuccessModal/>

      <Notifications/>
      <ScrollToTopButton/>

    </Fragment>
  );
};

export default memo(App);
