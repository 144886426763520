import React from 'react';
import './FormRow.scss';

const FormRow = ({className = '', ...props}) => {
  return (
      <div className={`form-row ${className}`}
          {...props}/>
  )
};

export default FormRow
