import React from 'react';
import './FooterLogo.scss';
import {Link} from 'react-router-dom';
import {LogoWhite} from '../../images';

const FooterLogo = ({className = '', ...props}) => {
  return (
      <Link className={`footer-logo ${className}`} to={"/"}>
        <LogoWhite style={{width: 100 + '%'}}/>
      </Link>
  )
};

export default FooterLogo;
