import React from 'react';
import './Page404Content.scss';
import Container from '../Container';
import Section from '../Section';
import SecondaryTitle from '../SecondaryTitle';
import Description from '../Description';
import ModifiedLink from '../ModifiedLink';
import {useTranslation} from 'react-i18next';

const Page404Content = ({...props}) => {
  const {t} = useTranslation();

  return (
      <Section white
               className="page-404">
        <Container className="page-404__container">
          <div className="page-404__content">
            <SecondaryTitle center
                            title="404"
                            className="page-404__title"
                            orange/>

            <Description center
                         description={t("Страница не найдена")}
                         className="page-404__description"/>

           <div className="page-404__button-wrap">
             <ModifiedLink title={t("На главную")}
                           button
                           url={'/'}/>
           </div>

          </div>

        </Container>
      </Section>
  );
};

export default Page404Content;
