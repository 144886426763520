import {NavLink} from 'react-router-dom';
import React from 'react';
import './ModifiedNavLink.scss';
import NotificationCount from '../NotificationCount';


const ModifiedNavLink = ({
                           url, title, className = '', large = false, medium = false,
                           small = false, icon: Icon = null, notification = false, style = {}, activeClassName, ...props
                         }) => {
  let handleClick = (e) => {
    const {disabled} = props;
    if ( disabled ) e.preventDefault();
  };

  return (
      <React.Fragment>
        <NavLink className={ `mod-link 
                             ${ className }
                             ${ small ? 'mod-link--small' : '' }
                             ${ medium ? 'mod-link--medium' : '' }
                             ${ large ? 'mod-link--large' : '' }
                             ${ Icon ? 'mod-link--has-icon' : '' }` }
                 style={ {...style} }
                 activeClassName={ activeClassName ? 'mod-link--active' : null }
                 onClick={ handleClick }
                 to={ url }
                 { ...props }>

          { Icon && (
              <div className="mod-link__icon">
                <Icon/>
              </div>
          ) }
          { notification
              ? <NotificationCount className="mod-link__notification"
                                   count={ notification }/>
              : null
          }
          <div className="mod-link__text">
            { title }
          </div>
        </NavLink>
      </React.Fragment>
  );
};

export default ModifiedNavLink;
