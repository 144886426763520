import React, {PureComponent} from 'react';
import './TransferForm.scss';
import Input from '../Input';
import Button from '../Button';
import {connect} from 'react-redux';
import {lastUtilityMeterData, sendNewMeterData} from '../../actions/paymentsActions';
import Errors from '../Errors';
import {notification} from '../Tools';
import {closeTransferModal} from '../../actions/modalActions';
import {ERROR, SUCCESS, SUCCESS_METERS_DATA} from '../Constants';
import {withTranslation} from 'react-i18next';


const initialState = {
  value: '',
  errors: new Errors({})
};

class TransferForm extends PureComponent {
  constructor (props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount () {
    const {bookingId, utilityId} = this.props;
    this.props.lastUtilityMeterData({bookingId, utilityId});
  }


  componentDidUpdate (prevProps, prevState, snapshot) {
    const {lastMeterData, currentMeterData} = this.props;
    if ( prevProps.currentMeterData !== this.props.currentMeterData ) {
      this.setState(prevState => ({
        ...prevState,
        value: lastMeterData === currentMeterData ? '' : currentMeterData
      }));
    }
  }

  handleSubmit = () => {
    const {utilityId, realtyId, bookingId, t} = this.props;
    const {value} = this.state;

    const data = {
      value: +value,
      realty_utility_id: utilityId,
      realty_id: realtyId
    };


    this.props.sendNewMeterData({bookingId, data})
        .then(() => {
          this.setState(initialState);
          this.props.closeTransferModal();
          notification(SUCCESS, t(SUCCESS_METERS_DATA));
        })
        .catch(errors => {
          notification(ERROR, errors.value);
        });
  };

  handleChange = (e) => {
    let newValue = e.target.validity.valid ? e.target.value : this.state.value;

    this.setState(prevState => ({
      ...prevState,
      value: newValue
    }));
  };


  render () {
    const {value} = this.state;
    const {utilityName, icon, lastMeterData, t} = this.props;

    return (
        <div className="transfer">
          {/*<div className="transfer__header">*/ }
          {/*  <div className="transfer__title">*/ }
          {/*    {utilityName}*/ }
          {/*  </div>*/ }
          {/*</div>*/ }

          <div className="transfer__body">
            <div className="transfer-table">

              <div className="transfer-table__head">
                <div className="transfer-table__title">
                  { t('Комуннальные') }
                </div>
                <div className="transfer-table__title">
                  { t('Прошлые') }
                </div>
                <div className="transfer-table__title">
                  { t('Текущие') }
                </div>
              </div>

              <div className="transfer-table__body">

                <div className="transfer-table__row">
                  <div className="transfer-table__cell">
                    <div className="transfer__icon">
                      <img src={ icon } alt={ utilityName }/>
                    </div>
                    <span className="transfer__name">
                    { t(utilityName) }
                  </span>
                  </div>
                  <div className="transfer-table__cell">
                    { lastMeterData }
                  </div>
                  <div className="transfer-table__cell">
                    <Input value={ value }
                           size="es"
                           name="value"
                           pattern="[0-9]*"
                           placeholder="0"
                           onChange={ this.handleChange }/>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="transfer__footer">
            <Button title="Отправить"
                    className="transfer__btn"
                    onClick={ this.handleSubmit }
                    submit/>
          </div>
        </div>
    );
  }

}


let mapStateToProps = (state) => ({
  bookingId: state.modal.transfer.payload.bookingId,
  utilityId: state.modal.transfer.payload.utilityId,
  utilityName: state.modal.transfer.payload.utilityName,
  icon: state.modal.transfer.payload.icon,
  lastMeterData: state.modal.lastMeterData,
  currentMeterData: state.modal.currentMeterData,
  realtyId: state.modal.transfer.payload.realtyId
});

export default withTranslation()(connect(mapStateToProps, {
  lastUtilityMeterData, sendNewMeterData, closeTransferModal
})(TransferForm));
