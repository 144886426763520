import * as actionTypes from './../actions/actionTypes';

const initialState = {
  realtyType: '',
};

const realtyTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CURRENT_REALTY_TYPE:
      return {
        ...state,
        realtyType: action.currentType,
      };
    default:
      return state;
  }
};

export default realtyTypeReducer;
