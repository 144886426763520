import {combineReducers} from 'redux';
import authReducer from './authReducer';
import modalReducer from './modalReducer';
import filterReducer from './filterReducer';
import realtyTypeReducer from './realtyTypeReducer';


const appReducer = combineReducers({
    auth: authReducer,
    modal: modalReducer,
    filters: filterReducer,
    realtyType: realtyTypeReducer
});


export default appReducer;
