import React from 'react';
import './EmailStatus.scss';
import {ApproveIcon, CloseIcon, MailIcon} from '../../icons';
import {FAILURE, SUCCESS} from '../Constants';
import {useTranslation} from 'react-i18next';


const EmailStatus = ({status, ...props}) => {
  const {t} = useTranslation();

  return (
      <div className="email-status">
        <div className="email-status__icon">
          <MailIcon/>

          { status === SUCCESS && <ApproveIcon className="email-status__badge email-status__badge--success"/> }
          { status === FAILURE && <CloseIcon className="email-status__badge email-status__badge--failure"/> }
        </div>
        <div className="email-status__description">
          { status === SUCCESS && (
              <React.Fragment>
                {t("Ваш электронный адрес подтвержден успешно")}
              </React.Fragment>
          ) }

          { status === FAILURE && (
              <React.Fragment>
                {t("Ссылка, по которой вы перешли, истекла или недействительна")}
              </React.Fragment>
          ) }
        </div>
      </div>
  );
};

export default EmailStatus;
