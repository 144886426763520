import React, {useEffect, useState} from 'react';
import './PaymentForm.scss';
import {connect} from 'react-redux';
import {fetchBooking} from '../../actions/bookingActions';

const PaymentForm = ({booking_id, ...props}) => {
  const [booking, setBooking] = useState('');

  useEffect(() => {
    fetchBooking(booking_id)
        .then(booking => {
          setBooking(booking)
        })
  }, [booking_id]);

  return (
      <div className="payment-info">
        <div className="payment-info__title">
          Вы можете оплатить услуги, отправив нужную сумму на расчётный счёт
        </div>

        <div className="payment-info__part payment-info__certificate">
          <span>ИП Герасимов Станислав Олегович</span>
          <span>ИНН 504227951241</span>
          <span>КПП Отсутствует</span>
        </div>

        <div className="payment-info__part payment-info__props">
          <strong>Банковские реквизиты:</strong>
          <span>Р/с № 40802810740000107681</span>
          <span>ПАО СБЕРБАНК</span>
          <span>БИК 044525225</span>
          <span>К/с 30101810400000000225</span>
        </div>

        <div className="payment-info__part payment-info__comment">
          <strong>Просим в назначении платежа не забыть указать:</strong>
          <span>«за коммунальные расходы {booking?.realty?.name} по договору № {booking.rental_agreement}»</span>
        </div>
      </div>
  )
};

let mapStateToProps = (state) => ({
  booking_id: state.modal.payment.payload.booking_id
});

export default connect(mapStateToProps)(PaymentForm)
