import React from 'react';
import './FormContainer.scss';

const FormContainer = ({className = '', center = false, ...props}) => {
  return (
      <div className={`form-container 
                       ${center ? 'form-container--center' : ''}
                      ${className}`}
           {...props}/>
  );
};

export default FormContainer;
