import React from 'react';
import './SecondaryTitle.scss';
import {useTranslation} from 'react-i18next';

const SecondaryTitle = ({className = '', title, orange = false, center = false, ...props}) => {
  const {t} = useTranslation();

  return (
      <h2 className={`secondary-title 
                      ${orange ? 'secondary-title--orange' : ''}
                      ${center ? 'secondary-title--center' : ''}
                      ${className}`}>
        { t(title) }
      </h2>
  );
};

export default SecondaryTitle;
