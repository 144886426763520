import React from 'react';
import './HotLine.scss';
import { MailIcon, PhoneIcon } from '../../icons';
import Phone from '../Phone';
import { useTranslation } from 'react-i18next';
import { useCurrentLocaleVersion } from '../../hooks/useCurrentLocaleVersion';

const HotLine = (props) => {
  const { t } = useTranslation();
  const { isRuVersion } = useCurrentLocaleVersion();

  if ( isRuVersion() ) {
    return (
      <div className="hot-line">
        <div className="hot-line__icon">
          <PhoneIcon/>
        </div>
        <div className="hot-line__main">
          <div className="hot-line__text">
            { t('Горячая линия') }
          </div>
          <div className="hot-line__phone">
            <Phone phone="74952215259"
                   formattedPhone="+7 (495) 221-52-59"/>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="hot-line hot-line--mail">
      <div className="hot-line__icon">
        <MailIcon/>
      </div>
      <div className="hot-line__main">
        <div className="hot-line__text">
          { t('Служба поддержки') }
        </div>
        <div className="hot-line__mail">
          <a href="mailto:docs@rentabelno.com">docs@rentabelno.com</a>
        </div>
      </div>
    </div>
  );

};

export default HotLine;
