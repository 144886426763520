import React from 'react';
import './ErrorBoundary.scss';
import MessageWhenIsNoData from '../MessageWhenIsNoData';
import {ERROR_LOADING_DATA} from '../Constants';
import Section from '../Section';
import Container from '../Container';

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      error: null,
      errorInfo: null
    };
  }

  componentDidCatch (error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  render () {
    if ( this.state.errorInfo ) {
      return (
          <Section white
                   className="error">
            <Container className="error__container">
              <div className="error__content">
                <MessageWhenIsNoData text={ ERROR_LOADING_DATA }/>
              </div>
            </Container>
          </Section>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
