import { RU } from '../components/Constants';

export const useCurrentLocaleVersion = () => {

  const getCurrentLocaleVersion = () => {
    return process.env.REACT_APP_CURRENT_LANGUAGE;
  };

  const isRuVersion = () => {
    return process.env.REACT_APP_CURRENT_LANGUAGE === RU;
  };

  return {
    getCurrentLocaleVersion,
    isRuVersion
  };
};
