import React from 'react';
import { useTranslation } from "react-i18next";
import './HeaderLinks.scss';
import StyledLink from '../StyledLink';
import {ABOUT_ROUTE, HOW_IT_WORK_ROUTE} from '../RouterContants';

const links = [
  {title: 'Как это работает?', url: HOW_IT_WORK_ROUTE},
  {title: 'О нас', url: ABOUT_ROUTE},
  // {title: 'Блог', url: `${process.env.REACT_APP_URL}/blog`},
];


const HeaderLinks = ({className = '', medium = false, ...props}) => {
  const { t } = useTranslation();

  return (
      <React.Fragment>
        { links.map((link, index) => <StyledLink url={ link.url }
                                                 medium={ medium }
                                                 key={ index } title={ t(link.title) }/>) }
      </React.Fragment>
  );
};

export default HeaderLinks;
