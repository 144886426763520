import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LngDetector from 'i18next-browser-languagedetector';
import {I18N_LANGUAGE} from './components/Constants';
import ru from './locales/ru/translation.json';
import ua from './locales/ua/translation.json';


// the translations
const resources = {
  ru: {
    translation: ru
  },
  ua: {
    translation: ua
  }
};

let languages = process.env.REACT_APP_LOCALES.split(',');

i18n.use(initReactI18next)
    .use(LngDetector)
    .init({
      supportedLngs: languages,
      detection: {
        order: ['localStorage', 'cookie'],
        lookupLocalStorage: I18N_LANGUAGE,
        caches: ['localStorage', 'cookie']
      },
      resources: resources,
      fallbackLng: languages, // use en if detected lng is not available
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });

export default i18n;
