import React from 'react';
import './Modal.scss';
import ReactModal from 'react-modal';
import {CloseIcon} from '../../icons';
import {useTranslation} from 'react-i18next';

ReactModal.setAppElement('#root');

const Modal = ({size, children = null, title = null, isOpen = false, onRequestClose = () => {}, ...props}) => {
  const {t} = useTranslation();

  return (
      <ReactModal isOpen={ isOpen }
                  { ...props }
                  onRequestClose={ onRequestClose }
                  className={ `modal 
                  ${ size ? `modal--${ size }` : '' }` }
                  overlayClassName="overlay">
        <div className="modal__content">
          <CloseIcon className="modal__close"
                     onClick={ onRequestClose }/>
          <div className="modal__header">
            <div className="modal__title">
              { t(title) }
            </div>
          </div>

          <div className="modal__body">
            { children }
          </div>
        </div>
      </ReactModal>
  );
};

export default Modal;
