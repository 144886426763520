import React from 'react';
import './MainLogo.scss';
import {Link} from 'react-router-dom';
import {LogoMain, LogoMini} from '../../images';

const MainLogo = (props) => {
  return (
      <Link className="logo" to={"/"}>
        <div className="logo__desktop">
          <LogoMain style={{width: `100%`}}/>
        </div>
        <div className="logo__mobile">
          <LogoMini style={{width: `100%`}}/>
        </div>

      </Link>
  )
};

export default MainLogo;
