import React from 'react';
import './Phone.scss';

const Phone = ({ className = '', phone = '', color = '', formattedPhone = '', ...props }) => {
  return (
    <a href={ `tel:+${ phone }` }
       className={ `phone 
                    ${ color ? `phone--${ color }` : '' }
                    ${ className }` }>
      { formattedPhone }
    </a>
  );
};

export default Phone;
