import React from 'react';
import './NotificationsMessage.scss';
import {ApproveIcon, BulbIcon, CloseIcon, ExclamationIcon} from '../../icons';
import {ERROR, INFO, SUCCESS, WARNING} from '../Constants';
import {useTranslation} from 'react-i18next';

const NotificationsMessage = ({type, message, ...props}) => {
  const {t} = useTranslation();

  return (
      <div className="notification-message">
        <div className={ `notification-message__icon 
            ${ type ? `notification-message__icon--${ type }` : '' }` }>

          { type === SUCCESS && <ApproveIcon/> }
          { type === WARNING && <ExclamationIcon/> }
          { type === ERROR && <CloseIcon/> }
          { type === INFO && <BulbIcon/> }
        </div>
        <div className="notification-message__text">
          { t(message) }
        </div>
      </div>
  );
};

export default NotificationsMessage;
