export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const LOGOUT = 'LOGOUT';
export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';

export const FETCH_USER = 'FETCH_USER';
export const FETCHING_USER = 'FETCHING_USER';

export const AUTH_ERROR = 'AUTH_ERROR';
export const REGISTER_ERROR = 'REGISTER_ERROR';

export const FETCH_LAST_METER_DATA = 'FETCH_LAST_METER_DATA';
// export const FETCH_UTILITY_DETAILS = 'FETCH_UTILITY_DETAILS';
// export const CLEAR_DETAILS_MODAL = 'CLEAR_DETAILS_MODAL';

export const GET_CURRENT_REALTY_TYPE = 'GET_CURRENT_REALTY_TYPE';

//filter actions
export const FETCH_CONVENIENCES_LIST = 'FETCH_CONVENIENCES_LIST';
export const FETCH_FLOOR_LIST = 'FETCH_FLOOR_LIST';
export const FETCH_SUBTYPE_LIST = 'FETCH_SUBTYPE_LIST';
export const FETCH_ROOMS_LIST = 'FETCH_ROOMS_LIST';
export const FETCH_RENT_TYPE_LIST = 'FETCH_RENT_TYPE_LIST';
