import React, {PureComponent} from 'react';
import './ResePasswordForm.scss';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {resetPassword} from '../../actions/authActions';
import Errors from '../Errors';
import queryString from 'query-string';
import FormControl from '../FromControl';
import Input from '../Input';
import Button from '../Button';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import TogglePasswordIcon from '../TogglePasswordIcon';
import {withTranslation} from 'react-i18next';

const initialState = {
  data: {
    token: null,
    email: '',
    password: '',
    passwordConfirmation: '',
  },
  errors: new Errors({}),
  passwordNew: false,
  passwordConfirm: false
};

class ResetPasswordForm extends PureComponent {
  constructor (props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount () {
    const {email, token} = queryString.parse(this.props.location.search);

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        email: email,
        token: token,
      }
    }));
  }

  togglePassword = () => {
    this.setState(prevState => ({
      ...prevState,
      passwordNew: !this.state.passwordNew
    }));
  };

  togglePasswordConfirm = () => {
    this.setState(prevState => ({
      ...prevState,
      passwordConfirm: !this.state.passwordConfirm
    }));
  };

  handleChange = (e) => {
    const {name, value, type, checked} = e.target;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [name]: type === 'checkbox' ? checked : value,
      }
    }));
  };


  handleSubmit = () => {
    const {data: {token, email, password, passwordConfirmation}} = this.state;

    if ( password.length < 8 ) {
      this.setState({
        errors: new Errors({password: 'Длинна пароля должна быть не менее 8 символов'})
      });
      return;
    } else if ( passwordConfirmation.length < 8 ) {
      this.setState({
        errors: new Errors({passwordConfirmation: 'Длинна повтореного пароля должна быть не менее 8 символов'})
      });
      return;
    } else if ( passwordConfirmation !== password ) {
      this.setState({
        errors: new Errors({passwordConfirmation: 'Подтвержденный пароль не совпадает с новым'})
      });
      return;
    }

    this.props.resetPassword({
      token: token,
      email: email,
      password: password,
      passwordConfirmation: passwordConfirmation,
    }).then(() => {
      this.setState(initialState);
      this.props.onSubmit();
    }).catch(errors => {
      this.setState({
        errors: new Errors(errors)
      });
    });
  };


  render () {
    const {data: {password, passwordConfirmation}, errors, passwordNew, passwordConfirm} = this.state;
    const {t} = this.props;

    return (
        <div className="reset-password">
          <div className="reset-password__description">
            {t("Введите новый пароль...")}
          </div>
          <FormContainer className="reset-password__container">
            <FormRow>
              <FormControl>
                <Input error={ errors.getError('password') }
                       type={ passwordNew ? 'text' : 'password' }
                       name="password"
                       value={ password }
                       onChange={ this.handleChange }
                       icon={ props => <TogglePasswordIcon onClick={ this.togglePassword }
                                                           { ...props }
                                                           showPassword={ passwordNew }/> }
                       placeholder={ t(`Новый пароль`) }
                />
              </FormControl>
            </FormRow>
            <FormRow>
              <FormControl>
                <Input error={ errors.getError('passwordConfirmation') }
                       type={ passwordConfirm ? 'text' : 'password' }
                       name="passwordConfirmation"
                       value={ passwordConfirmation }
                       onChange={ this.handleChange }
                       icon={ props => <TogglePasswordIcon onClick={ this.togglePasswordConfirm }
                                                           { ...props }
                                                           showPassword={ passwordConfirm }/> }
                       placeholder={ t(`Повторите пароль`) }
                />
              </FormControl>
            </FormRow>

            <FormRow>
              <FormControl>
                <Button submit
                        title="Сменить пароль"
                        fullWidth={ true }
                        onClick={ this.handleSubmit }/>
              </FormControl>
            </FormRow>

          </FormContainer>

        </div>
    );
  }
}

export default withTranslation()(connect(null, {resetPassword})(withRouter(ResetPasswordForm)));
