import React, {useRef, useState} from 'react';
import './ProfileDropdown.scss';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import Button from '../Button';
import {UserIcon} from '../../icons';
import HeaderProfileLinks from '../HeaderProfileLinks/HeaderProfileLinks';
import LogoutButton from '../LogoutButton/LogoutButton';
import Divider from '../Divider';

const ProfileDropdown = ({userName, ...props}) => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef();

  let showMenuHandler = () => {
    setOpen(!isOpen);
  };

  useOnClickOutside(ref, showMenuHandler);

  return (
      <Button dropdown
              className={"profile__dropdown-button"}
              icon={() => <UserIcon/>}
              active={isOpen}
              onClick={showMenuHandler}
              title={userName}>
        {isOpen &&
        <div className="profile__dropdown"
             ref={ref}>
          <HeaderProfileLinks className="dropdown-links dropdown-links--right"
                              onClick={showMenuHandler}/>
          <Divider className="profile__dropdown-divider"
                   color="silver"/>
          <LogoutButton/>
        </div>
        }
      </Button>
  );
};

export default ProfileDropdown;
