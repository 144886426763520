import React from 'react';
import './MessageWhenIsNoData.scss';
import {useTranslation} from 'react-i18next';


const MessageWhenIsNoData = ({className = '', tag: Tag = 'h2', text, ...props}) => {
  const {t} = useTranslation();

  return (
      <div className={ `message-when-no-data ${ className }` }>
        <Tag className="message-when-no-data__text">
          { t(text) }
        </Tag>
      </div>
  );
};

export default MessageWhenIsNoData;
