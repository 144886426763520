import React from 'react';
import {Slide, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Notifications.scss';


const Notifications = ({...props}) => {
  return (
      <ToastContainer autoClose={ 3000 }
                      { ...props }
                      position="top-right"
                      hideProgressBar
                      newestOnTop
                      closeOnClick
                      rtl={ false }
                      pauseOnFocusLoss
                      draggable
                      limit={ 5 }
                      transition={ Slide }
                      closeButton={ true }
                      className="notification__container"
                      toastClassName="notification__toast"
                      bodyClassName="notification__body"
                      pauseOnHover/>
  );
};

export default Notifications;
