import axios from 'axios';
import * as actionTypes from './actionTypes';
import queryString from 'query-string';

export const fetchPayments = ({id, query}) => {
  return new Promise((resolve, reject) => {
    axios.get(`${ id }/payments?${  queryString.stringify(query) }`)
        .then(response => {
          resolve(response.data.results)
        })
        .catch(error => {
          reject(error.response?.data?.errors);
        });
  })

};

//bookings utilities logs, communal
export const fetchUtilityLogs = ({booking_id, utility_id, query}) => {
  let url;
  if ( utility_id === 0 ) {
    url = `/bookings/${ booking_id }/utilities/logs?${ queryString.stringify(query) }`;
  } else {
    url = `/bookings/${ booking_id }/utilities/logs/${ utility_id }?${ queryString.stringify(query) }`;
  }

  return new Promise((resolve, reject) => {
    axios.get(url)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error);
        });
  });
};

export const fetchBookingsCounters = (booking_id) => {
  return new Promise((resolve, reject) => {
    axios.get(`/bookings/${ booking_id }/utilities`)
        .then(response => {
          resolve(response.data.results);
        }).catch(error => {
      reject(error);
    });
  });
};


export const lastUtilityMeterData = ({bookingId, utilityId}) => dispatch => {
  axios.get(`/utilities/${ bookingId }/${ utilityId }/meter_data/last`)
      .then(response => {
        dispatch({
          type: actionTypes.FETCH_LAST_METER_DATA,
          data: response.data.results
        });
      })
      .catch(error => {
        console.log(error.response?.data?.errors);
      });
};

export const sendNewMeterData = ({bookingId, data}) => dispatch => {
  return new Promise((resolve, reject) => {
    axios.post(`/utilities/${ bookingId }/meter_data/store`, {...data})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error.response?.data?.errors);
        });
  });
};
