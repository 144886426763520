import React from 'react';
import {PRODUCTION} from './Constants';
import {YMInitializer} from 'react-yandex-metrika';

const YandexMetrika = ({...props}) => {
  return (
      <>
        { process.env.NODE_ENV === PRODUCTION &&
          <YMInitializer accounts={ [45051548] }
                         options={ {
                           clickmap: true,
                           trackLinks: true,
                           accurateTrackBounce: true,
                           webvisor: true
                         } }
                         version="2"/>
        }
      </>
  );
};

export default YandexMetrika;
