import React from 'react';
import './ProfileButtons.scss';
import {useSelector} from 'react-redux';
import ProfileDropdown from '../ProfileDropdown';

const ProfileButtons = ({...props}) => {
  const user = useSelector(state => state.auth.user);

  return (
      <React.Fragment>
        <div className="profile-links">
          <ProfileDropdown userName={user?.full_name}/>
        </div>
      </React.Fragment>
  );
};

export default ProfileButtons;
