import React, {PureComponent} from 'react';
import Modal from '../Modal';
import './EmailVerifyModal.scss'
import {emailVerify} from '../../actions/authActions';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';
import EmailStatus from '../EmailStatus';
import {FAILURE, SUCCESS} from '../Constants';

const initialState = {
  isOpen: false,
  status: null,
};

class EmailVerifyModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount() {
    const {client_id} = this.props.match.params;
    const {signature, expires} = queryString.parse(this.props.location.search);

    this.props.emailVerify({
      client_id: client_id,
      signature: signature,
      expires: expires,
    }).then(() => {
      this.setState({
        status: SUCCESS,
      });
    }).catch(() => {
      this.setState({
        status: FAILURE,
      });
    });

    this.setState({
      isOpen: this.props.isOpen || false,
    })

  }

  handleClose = () => {
    this.setState({
      isOpen: false,
    });

    this.props.history.push({
      pathname: `/`
    });

    // window.location.reload();
  };


  render() {
    const {isOpen, status} = this.state;

    return (
        <Modal isOpen={isOpen}
               onRequestClose={this.handleClose}
               title={'Подтверждение электронного адреса'}>

          <EmailStatus status={status}/>
        </Modal>
    )
  }
}

export default connect(null, {emailVerify})(withRouter(EmailVerifyModal));
