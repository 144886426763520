import React from 'react';
import {ApartmentIcon, ApartmentLoftIcon, ApartmentRoomIcon, CalendarIcon, MoonIcon, StarIcon} from '../../icons';
import StyledLink from '../StyledLink';
import Divider from '../Divider';
import {APARTMENTS_ROUTE, DAILY_ROUTE, EVENTS_ROUTE, FLAT_ROUTE, LOFT_ROUTE, LONG_TERM_ROUTE} from '../RouterContants';
import {uuidv4} from '../Tools';
import {useTranslation} from 'react-i18next';

const links = [
  {title: 'Посуточно', url: DAILY_ROUTE, icon: () => <MoonIcon/>},
  {title: 'Долгосрочно', url: LONG_TERM_ROUTE, icon: () => <CalendarIcon/>},
  {title: 'Мероприятия', url: EVENTS_ROUTE, icon: () => <StarIcon/>},
];

const apartments_links = [
  {title: 'Апартаменты', url: APARTMENTS_ROUTE, icon: () => <ApartmentIcon/>},
  {title: 'Лофт', url: LOFT_ROUTE, icon: () => <ApartmentLoftIcon/>},
  {title: 'Квартира', url: FLAT_ROUTE, icon: () => <ApartmentRoomIcon/>},
];

const HeaderNavigationDropdown = ({className = '', closeDropdown, ...props}) => {
  const {t} = useTranslation();

  return (
      <div className={ `navigation-dropdown ${ className }` }
                        onClick={ closeDropdown }>
        { apartments_links.map(link => <StyledLink title={ t(link.title) }
                                                   url={ link.url }
                                                   activeClassName="link--active"
                                                   onClick={ closeDropdown }
                                                   key={ uuidv4() }
                                                   icon={ link.icon }/>) }
        <Divider color="silver"/>
        { links.map(link => <StyledLink title={ t(link.title) }
                                        url={ link.url }
                                        key={ uuidv4() }
                                        activeClassName="link--active"
                                        disabled={ link.disabled }
                                        icon={ link.icon }/>) }
      </div>
  );
};

export default HeaderNavigationDropdown;
