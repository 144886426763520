import React from 'react';
import Modal from '../Modal';
import PaymentForm from '../PaymentForm';
import {connect} from 'react-redux';
import {closePaymentModal, openPaymentModal} from '../../actions/modalActions';


const PaymentModal = ({isOpen, ...props}) => {

  let handleClose = () => {
    props.closePaymentModal()
  };


  return (
      <React.Fragment>
        <Modal isOpen={isOpen}
               onRequestClose={handleClose}
               title="Оплата">
          <PaymentForm/>
        </Modal>
      </React.Fragment>

  )
};

let mapStateToProps = (state) => ({
  isOpen: state.modal.payment.isOpen
});

export default connect(mapStateToProps, {closePaymentModal,openPaymentModal})(PaymentModal);
