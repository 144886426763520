import { useCurrentLocaleVersion } from '../../hooks/useCurrentLocaleVersion';

const Meta = () => {
  const { isRuVersion } = useCurrentLocaleVersion();
  let metaDescription = document.querySelector('meta[name="description"]');

  if ( isRuVersion() ) {
    metaDescription.setAttribute('content', 'Аренда квартир в Москве. Рентабельно - актуальные объявления об аренде квартир от собственников.');
  }

  metaDescription.setAttribute('content', 'Аренда квартир. Рентабельно - актуальные объявления об аренде квартир от собственников.');

  return null;
};

export default Meta;
