import React from 'react';
import './Divider.scss';

const Divider = ({className = '', color = '', ...props}) => {
  return (
      <hr className={`${className} 
                      ${color ? `divider--${color}` : ''}
                      divider`}/>
  );
};

export default Divider;
