import React from 'react';
import Page404Content from '../../components/Page404Content/Page404Content';

const Page404 = ({...props}) => {
  return (
      <Page404Content/>
  );
};

export default Page404;
