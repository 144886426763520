import ru from "date-fns/locale/ru";
import uk from "date-fns/locale/uk";
import {registerLocale} from 'react-datepicker';

const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
const days = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

const monthsUK = ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'];
const daysUK = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Нд'];

ru.localize.month = n => months[n];
ru.localize.day = d => days[d];

uk.localize.month = n => monthsUK[n];
uk.localize.day = d => daysUK[d];

registerLocale('ru', ru);
registerLocale('uk', uk);
