import React from 'react';
import './SuspenseLoader.scss';
import Section from '../Section';
import Container from '../Container';
import {LogoMini} from '../../images';

const SuspenseLoader = ({...props}) => {
  return (
      <Section white
               className="suspense-loader">
        <Container className="suspense-loader__container">
          <div className="suspense-loader__content">
            <LogoMini className="suspense-loader__icon"/>
          </div>
        </Container>
      </Section>
  );
};

export default SuspenseLoader;
