import axios from 'axios';
import * as actionTypes from './actionTypes';
import queryString from 'query-string';
import {handleLocalStorage, handleRemoveLocalStorage, notification} from '../components/Tools';
import {ERROR, FORBIDDEN, FORBIDDEN_MESSAGE, SITE_AUTH_CHECK} from '../components/Constants';

export const setCurrentUser = (payload) => ({
  type: actionTypes.FETCH_USER,
  user: payload.data.results
});

export const fetchUser = () => async dispatch => {
  const response = await axios.get(`/user`);

  dispatch(setCurrentUser(response));
};


export const login = (data) => {
  return new Promise((resolve, reject) => {
    axios.get(`/sanctum/csrf-cookie`).then(() => {
      axios.post(`/login`, data)
        .then(response => {
          handleLocalStorage(SITE_AUTH_CHECK, 'true');
          resolve(response);
        })
        .catch(error => {
          if(error.response?.data.status === FORBIDDEN) {
            notification(ERROR, FORBIDDEN_MESSAGE)
          }
          reject(error.response?.data?.errors);
        });
    });
  });
};


export const register = (data) => dispatch => {
  return new Promise((resolve, reject) => {
    axios.get('/sanctum/csrf-cookie')
        .then(() => {
          axios.post('/register', data)
              .then(response => {
                dispatch({
                  type: actionTypes.REGISTER,
                  user: response.data.results
                });
                resolve(response);
              })
              .catch(error => {
                reject(error.response?.data?.errors);
              });
        });
  });
};


export const forgotPassword = ({email}) => dispatch => {
  return new Promise((resolve, reject) => {
    axios.get('/sanctum/csrf-cookie')
        .then(() => {
          axios.post('/password/email', {
            email: email,
          }).then(response => {
            resolve(response);
          }).catch(error => {
            reject(error.response?.data?.errors);
          });
        });
  });

};


export const logout = () => dispatch => {
  axios.post('/logout')
      .then(() => {
        dispatch({
          type: actionTypes.LOGOUT
        });
        handleRemoveLocalStorage(SITE_AUTH_CHECK);
      });
};


export const emailVerify = ({client_id, signature, expires}) => dispatch => {
  return new Promise((resolve, reject) => {
    axios.get('/sanctum/csrf-cookie').then(() => {
      axios.get(`/email/verify/${ client_id }?` + queryString.stringify({
        signature: signature,
        expires: expires,
      })).then(response => {
        resolve();
      }).catch(error => {
        reject(error.response?.data?.errors);
      });
    });
  });
};

export const resetPassword = ({token, email, password, passwordConfirmation}) => dispatch => {
  return new Promise((resolve, reject) => {
    axios.get('/sanctum/csrf-cookie').then(() => {
      axios.post('/password/reset', {
        token: token,
        email: email,
        password: password,
        password_confirmation: passwordConfirmation,
      }).then(response => {
        resolve();
      }).catch(error => {
        reject(error.response?.data?.errors);
      });
    });
  });
};

