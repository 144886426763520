import * as actionTypes from './../actions/actionTypes';

const initialState = {
  conveniences: [],
  floor: [],
  subtype: [],
  rooms: [],
  rent_type: [],
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CONVENIENCES_LIST:
      return {
        ...state,
        conveniences: action.payload,
      };
    case actionTypes.FETCH_FLOOR_LIST:
      return {
        ...state,
        floor: action.payload,
      };
    case actionTypes.FETCH_SUBTYPE_LIST:
      return {
        ...state,
        subtype: action.payload,
      };
    case actionTypes.FETCH_ROOMS_LIST:
      return {
        ...state,
        rooms: action.payload,
      };
    case actionTypes.FETCH_RENT_TYPE_LIST:
      return {
        ...state,
        rent_type: action.payload,
      };
    default:
      return state;
  }
};

export default filterReducer;
