import React from 'react';
import {EyeHideIcon, EyeShowIcon} from '../../icons';


const TogglePasswordIcon = ({showPassword, onClick, ...props}) => {
  return (
        showPassword
          ? <EyeHideIcon onClick={onClick} style={{cursor: 'pointer'}} {...props}/>
          : <EyeShowIcon onClick={onClick} style={{cursor: 'pointer'}} {...props}/>

  )
};

export default TogglePasswordIcon;
