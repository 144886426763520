import React from 'react';
import './Social.scss';
import {FbIcon, InstaIcon, VkIcon} from '../../icons';

const socials = [
  {icon: () => <VkIcon/>, url: 'https://vk.com/rentabelno_com'},
  {icon: () => <FbIcon/>, url: 'https://www.facebook.com/rentabelno/'},
  {icon: () => <InstaIcon/>, url: 'https://www.instagram.com/rentabelno'},
];

const SocialLink = ({icon: Icon = null, className = '', url, ...props}) => {
  return (
      <a href={url} className={className} target="_blank" rel="noopener noreferrer">
        <Icon/>
      </a>
  )
};


const Social = ({className = '', label, ...props}) => {
  return (
      <div className={`social ${className}`}>
        <div className="social__label">
          { label }
        </div>
        <div className="social-list">
          {socials.map((social, index) => <SocialLink key={index}
                                                                                            className="social-list__link"
                                                                                            icon={social.icon}
                                                                                            url={social.url}/>)}
        </div>
      </div>
  )
};

export default Social;
