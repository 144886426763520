import React from 'react';
import './Menu.scss';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const Menu = ({className = '', label, disabled = false, links, ...props}) => {
  const location = useLocation();
  const {t} = useTranslation();

  return (
      <div className={ `menu ${ className }` }>
        <div className="menu__label">
          { label }
        </div>
        <div className="menu-list">
          { links.map((link, index) => <Link key={ index }
                                             className={ `menu-list__link 
                                                        ${ disabled ? 'menu-list__link--disabled' : '' }
                                                        ${ location.pathname === link.url ? 'menu-list__link--disabled' : '' }
                                                        ` }
                                             to={ link.url }>
            { t(link.title) }
          </Link>) }
        </div>
      </div>
  );
};

export default Menu;
