import React from 'react';
import './FormControl.scss';

const FormControl = ({className = '', size = '',  ...props}) => {
  return (
      <div {...props}
          className={`form-control 
                      ${size ? `form-control--${size}` : ''}
                      ${className}`}
      />
  );
};

export default FormControl;
