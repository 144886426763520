import React from 'react';
import MainLogo from '../MainLogo';
import HotLine from '../HotLine';
import HeaderNavigation from '../HeaderNavigation';
import {useTranslation} from 'react-i18next';

const HeaderBottom = (props) => {
  const {t} = useTranslation();

  return (
      <div className="header__bottom">
        <div className="header__bottom-left">
          <div className="header__logo">
            <MainLogo/>
          </div>
          <div className="header__call">
            <HotLine/>
          </div>
        </div>
        <div className="header__bottom-right">
          <div className="header__bottom-navigation">
            <div className="header__rent-title">
              { t('Предлагаем в аренду') }:
            </div>
            <HeaderNavigation/>
          </div>
        </div>
      </div>
  );
};

export default HeaderBottom;
