import React from 'react';
import './StyledLink.scss';
import {NavLink, useLocation} from 'react-router-dom';


const StyledLink = ({url, title, active = false, hover = false, disabled = false, className = '', activeClassName = '',
                      dropdown = false,
                      large = false, medium = false, icon: Icon = null, style = {}, children, ...props}) => {
  const location = useLocation();

  return (
      <React.Fragment>
        <NavLink  className={`link 
                  ${className}
                  ${medium ? 'link--medium' : ''}
                  ${large ? 'link--large' : ''}
                  ${Icon ? 'link--has-icon' : ''}
                  ${disabled ? 'link--disabled' : ''}
                  ${dropdown ? 'link--with-dropdown' : ''}
                  ${active ? 'link--active' : ''}
                  ${hover ? 'link__hover' : ''}
                  ${location.pathname === url ? 'link--current': ''}
                  `}
                  activeClassName={activeClassName}
                  style={{...style}}
                  {...props}
                  to={url}>

          {Icon && (
              <div className="link__icon">
                <Icon/>
              </div>
          )}
          <div className="link__text">
            {title}
          </div>

          {dropdown &&
            <>
              { children }
            </>
          }
        </NavLink>
      </React.Fragment>
  )
};

export default StyledLink;
