import axios from 'axios';
import queryString from 'query-string';

export const fetchBookings = () => {
  return new Promise((resolve, reject) => {
    axios.get(`/bookings`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error.response?.data?.errors);
        });
  });

};


export const fetchBooking = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`/bookings/${ id }`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error.response?.data?.errors);
        });
  });
};

export const createBookingRequest = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/booking/request`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error.response?.data?.errors);
        });
  });
};

export const getBookingCommunalInvoices = (booking_id, query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/bookings/${booking_id}/invoices?${queryString.stringify(query)}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error.response?.data?.errors);
        });
  });
};
