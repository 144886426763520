import React from 'react';
import './Copyright.scss';

const Copyright = ({className = '', ...props}) => {
  return (
      <div className={`copyright ${className}`}>
        &copy; {new Date().getFullYear()} Рентабельно
      </div>
  )
};

export  default Copyright
