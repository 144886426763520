import React from 'react';
import './SuccesRequestDescription.scss';
import {CheckIcon} from '../../icons';
import {useTranslation} from 'react-i18next';

const SuccessRequestDescription = ({description, ...props}) => {
  const {t} = useTranslation();
  return (
      <div className="success">
        <div className="success__inner">
          <div className="success__icon">
            <CheckIcon/>
          </div>
          <div className="success__text">
            { t(description) }
          </div>
        </div>
      </div>
  );
};

export default SuccessRequestDescription;
