import * as actionTypes from './actionTypes';

export const openModal = ({modalType = '', payload = null}) => ({
  type: actionTypes.OPEN_MODAL,
  modalType: modalType,
  payload: payload
});

export const closeModal = ({modalType = ''}) => ({
  type: actionTypes.CLOSE_MODAL,
  modalType: modalType,
});

export const openTransferModal = ({bookingId = null, utilityId = null, utilityName = null, icon = null, realtyId = null}) => openModal({
  modalType: 'transfer',
  payload: {
    bookingId: bookingId,
    utilityId: utilityId,
    utilityName: utilityName,
    icon: icon,
    realtyId: realtyId,
  },
});

export const closeTransferModal = () => closeModal({
  modalType: 'transfer',
});


export const openPaymentModal = (id) => openModal({
  modalType: 'payment',
  payload: {
    booking_id: id
  }
});

export const closePaymentModal = () => closeModal({
  modalType: 'payment',
});

export const openSuccessModal = (description) => openModal({
  modalType: 'success',
  payload: description
});

export const closeSuccessModal = () => closeModal({
  modalType: 'success',
});
