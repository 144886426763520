import React from 'react';
import './Section.scss';

const Section = ({className = '', withOutPaddingTop = false, silver = false, white = false, ...props}) => {
  return (
      <div {...props}
           className={`${className} 
                      section 
                      ${silver ? 'section--silver' : ''} 
                      ${withOutPaddingTop ? 'section--without-top' : ''} 
                      ${white ? 'section--white' : ''}`}
      />
  );
};

export default Section;
